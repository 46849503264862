/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import loginRequiredPaywall from './utils/loginRequiredPaywall';
import utils from './utils/wallUtils';
import applyTheme from './themes';
import initCounter from './countdownTimer';

const isProd = window.digitalData.page.attributes.environment === 'prod';
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  useZephr: true,
});

function initLoginwall() {
  !isProd && console.log('initLoginwall');

  // Loginwall logic
  loginRequiredPaywall({ channel: window.digitalData.channel.title });

  // Loginwall styling
  // Classes from londo cant be extended in zephr stylesheets, must add with js
  const darkTheme = ['body.videosite', 'body.gallery', '.article.-rich'].some((cls) => document.querySelector(cls));
  document.querySelector('.paywall__cta').classList.add('km-button-rounded__brown');
  document
    .querySelector('.js-login-link')
    .classList.add(
      'no-link-styles',
      'a-button-primary',
      darkTheme ? 'km-button-rounded__black-outlined' : 'km-button-rounded__white-outlined',
    );

  document.querySelector('.paywall-css-container').classList.add(darkTheme ? '-theme-dark' : '-theme-normal');
  if (darkTheme) {
    document.querySelector('.article.-rich')?.classList.add('-with-paywall-dark');
  }
}

// PAYWALL JS, mpcdn paywall.js

export const initPaywall = () => {
  !isProd && console.log('initPaywall');
  if (document.querySelector('.paywall-css-container')) {
    applyTheme();
    initCounter();
  }
  // Below is a ~copy-paste of KalevaPaywall.init(). TODO: cleaning
  const searchParams = new URLSearchParams(document.location.search);
  const kvatxid = searchParams.get('kvatxid') || utils.generateTransactionId();

  const paywallSlug = `${window.digitalData.channel.slug}-paywall`;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'Paywall hit',
    paywallSlug,
    kvatxid,
  });

  const currentUrlWithKvatxid = utils.setUrlParameter(document.location.href, 'kvatxid', kvatxid);

  const ctaLinks = document.querySelectorAll('a.js-cta-link');
  ctaLinks.forEach((ctaLink) => {
    // replicate utm params
    new URLSearchParams(utils.getUtmParams()).forEach((value, key) => {
      ctaLink.href = utils.setUrlParameter(ctaLink.href, key, value);
    });

    ctaLink.href = utils.setUrlParameter(ctaLink.href, 'kvatxid', kvatxid);
    ctaLink.href = utils.setUrlParameter(ctaLink.href, 'content_url', currentUrlWithKvatxid);
  });

  const loginLinks = document.querySelectorAll('a.js-login-link');
  if (loginLinks.length > 0) {
    loginLinks.forEach((loginLink) => {
      loginLink.href = utils.setUrlParameter(loginLink.href, 'next', currentUrlWithKvatxid);
    });
  }

  // KalevaPaywallContent.bindListeners();
  const parentFrameLinks = document.querySelectorAll('a.js-parent-frame-link');
  Array.prototype.forEach.call(parentFrameLinks, (element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      if (e.target.className.indexOf('js-cta-link') > -1) {
        window.dataLayer.push({
          event: 'Paywall CTA click',
        });
        setTimeout(() => {
          document.location.href = e.target.getAttribute('href');
        }, 100);
      } else {
        document.location.href = e.target.getAttribute('href');
      }
    });
  });
};

function init() {
  const isPay = document.querySelector('.paywall-css-container').classList.value.includes('-type-pay');
  !isProd && console.log('init()::type', isPay ? 'pay' : 'login');
  setTimeout(() => {
    if (isPay) {
      initPaywall();
    } else {
      initLoginwall();
    }
  }, 0);

  setTimeout(() => {
    utils.addBenefits('.paywall__benefits', 'fa-check');
    utils.addBenefits('.paywall__benefits--horizontal', 'fa-check-circle');
  }, 0);

  setTimeout(() => {
    if (isPay) {
      const { theme } = document.querySelector('.paywall-css-container').dataset;
      let logoSrc;
      theme === 'normal' &&
      ['body.videosite', 'body.gallery', '.article.-rich'].some((cls) => document.querySelector(cls))
        ? (logoSrc = window._APP_GLOBALS.CHANNEL_LOGO_WHITE)
        : (logoSrc = window._APP_GLOBALS.CHANNEL_LOGO_BLACK);

      document.querySelector('.paywall-css-container .paywall__channel-logo').src = logoSrc;
    }
  }, 0);

  setTimeout(() => {
    [
      '.paywall__main-title',
      '.paywall__subtitle',
      '.paywall__benefits--text',
      '.paywall__cta-login-text-before',
      '.js-login-link',
    ].forEach((name) => {
      utils.addUnderline(name);
      utils.addBold(name);
    });
  }, 0);
}

export default () => {
  if (document.querySelector('.paywall-css-container .paywall__main-title') || document.readyState === 'complete') {
    init();
  } else {
    window.addEventListener('load', init);
  }
};
