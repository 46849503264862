/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import initModal from '../../js/modal';
import utils from '../../js/utils/wallUtils';
import { initPaywall } from '../../js/wall';

const E_PAPER_LOGINWALL_CLASSNAME = 'epaper-wall.-type-login';
const E_PAPER_PAYWALL_CLASSNAME = 'epaper-wall.-type-pay';

const initGlobalArchivePaywallUtils = (wallClassName) => {
  if (!document.querySelector(`.${wallClassName}`) || document.querySelector(`.modal .${wallClassName}`)) return;

  utils.addBenefits(`.${wallClassName} .epaper__benefits`, 'fa-check-circle');

  ['.epaper__title', '.epaper__subtitle', '.paywall__benefits--text'].forEach((selector) => {
    utils.addUnderline(`.${wallClassName} ${selector}`);
    utils.addBold(`.${wallClassName} ${selector}`);
  });

  const modalInstance = initModal(wallClassName);
  const isPaywall = wallClassName === E_PAPER_PAYWALL_CLASSNAME;
  const wallUtils = {
    [isPaywall ? 'displayPaywall' : 'displayLoginwall']: modalInstance.openModal,
  };

  if (isPaywall) {
    wallUtils.isSubscriber = false;
  }

  if (!window.LorienZephrUtils) {
    window.LorienZephrUtils = { ArchivePaywallUtils: {} };
  }

  window.LorienZephrUtils.ArchivePaywallUtils = {
    ...window.LorienZephrUtils.ArchivePaywallUtils,
    ...wallUtils,
  };
};

initGlobalArchivePaywallUtils(E_PAPER_LOGINWALL_CLASSNAME);
initGlobalArchivePaywallUtils(E_PAPER_PAYWALL_CLASSNAME);
initPaywall();
