const generateTransactionId = () => {
  const base36Timestamp = Date.now().toString(36);
  const MAX_INT = 2 ** 53 - 1;
  const base36RandomInt = Math.floor(Math.random() * MAX_INT).toString(36);
  return base36Timestamp + base36RandomInt;
};

const getUtmParams = () => {
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
  const urlParams = new URLSearchParams(window.location.search);
  const filteredParams = new URLSearchParams();

  urlParams.forEach((value, key) => {
    if (utmParams.includes(key) && value !== '') {
      filteredParams.append(key, value);
    }
  });

  return filteredParams.toString();
};

const setUrlParameter = (url, rawKey, rawValue) => {
  const urlObject = new URL(url);
  const params = urlObject.searchParams;

  if (rawValue === undefined || rawValue === null || rawValue === '') {
    params.delete(rawKey); // Remove the parameter if the value is empty
  } else {
    params.set(rawKey, rawValue); // Set or update the parameter value
  }

  return urlObject.href;
};

const getLoginwallUrlParams = (source, campaign, trxId) => {
  const utmParams = `&utm_medium=paywall&utm_source=${source}&utm_campaign=${campaign}`;
  const logintxidParam = `logintxid=${trxId}`;
  const sep = `?`;
  const nextParam = `&next=${encodeURIComponent(
    window.location.pathname + window.location.search + sep + logintxidParam + utmParams,
  )}`;
  return `${nextParam}&${logintxidParam}${utmParams}`;
};

function addBenefits(containerClass, iconClass) {
  const container = document.querySelector(containerClass);
  const benefits = container.innerHTML;
  if (benefits) {
    container.innerHTML = '';
    container.style.display = '';
    benefits
      .split('\n')
      .map((t) => t.trim())
      .filter(Boolean)
      .forEach((benefit) => {
        container.innerHTML += `
    <div class="a-paragraph__2 paywall__benefits--benefit">
      <i class="fas ${iconClass} paywall__benefits--icon"></i><span class="paywall__benefits--text">${benefit}</span>
    </div>`;
      });
  }
}

// STYLISTIC UTILS

const addSpanToSelector = (splitter, spanClass) =>
  function adder(selector) {
    function addToSingle(elem) {
      if (elem.innerHTML.includes(spanClass)) return;
      const parts = elem.innerHTML.split(splitter);
      if (parts.length > 1 && parts.length % 2 === 1) {
        const result = parts.map((part, idx) => (idx % 2 === 1 ? `<span class="${spanClass}">${part}</span>` : part));
        // eslint-disable-next-line no-param-reassign
        elem.innerHTML = result.join('');
      }
    }

    const elems = document.querySelectorAll(selector);
    if (elems.length) {
      Array.from(elems).forEach((elem) => addToSingle(elem));
    }
  };

const addUnderline = addSpanToSelector('_', 'paywall__span-underline');
const addBold = addSpanToSelector('*', 'paywall__span-bold');

export default {
  generateTransactionId,
  getUtmParams,
  setUrlParameter,
  getLoginwallUrlParams,
  addBenefits,
  addUnderline,
  addBold,
};
