/* eslint-disable no-param-reassign */

export const THEMES = {
  NORMAL: 'normal',
  DARK: 'dark',
  AUTUMN: 'autumn',
  BLACKFRIDAY: 'black-friday',
  CHRISTMAS: 'christmas',
  CULTURE: 'culture',
  EASTER: 'easter',
  FATHERS_DAY: 'fathers-day',
  IIJOKISEUTU: 'iijokiseutu',
  INDEPENDENCE_DAY: 'independence-day',
  KALEVA: 'kaleva',
  KOILLISSANOMAT: 'koillissanomat',
  LAPINKANSA: 'lapinkansa',
  LIVE: 'live',
  MAY_DAY: 'may-day',
  MIDSUMMER: 'midsummer',
  MOTHERS_DAY: 'mothers-day',
  NEW_YEAR: 'new-year',
  PRIDE: 'pride',
  PYHAJOKISEUTU: 'pyhajokiseutu',
  RAAHENSEUTU: 'raahenseutu',
  RANTALAKEUS: 'rantalakeus',
  SIIKAJOKILAAKSO: 'siikajokilaakso',
  SINGLES_DAY: 'singles-day',
  SPORTS: 'sports',
  SPRING: 'spring',
  SUMMER: 'summer',
  VALENTINES_DAY: 'valentines-day',
  WINTER: 'winter',
  GOOD_NEWS: 'good-news',
};

export const debounce = (func, delay) => {
  const time = delay || 100;
  let timer;
  // eslint-disable-next-line func-names
  return function (...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), time);
  };
};

export const shouldSwapImageOnresize = (theme) => {
  if (!theme) return false;

  return ![
    THEMES.PYHAJOKISEUTU,
    THEMES.IIJOKISEUTU,
    THEMES.RANTALAKEUS,
    THEMES.RAAHENSEUTU,
    THEMES.SIIKAJOKILAAKSO,
    THEMES.SPORTS,
    THEMES.CULTURE,
    THEMES.SPRING,
    THEMES.SUMMER,
    THEMES.AUTUMN,
    THEMES.WINTER,
  ].includes(theme);
};

const genreThemesBgColorByChannel = {
  [THEMES.SPORTS]: {
    lapinkansafi: '#F3F7EC',
    kalevafi: '#E4F4F8',
  },
  [THEMES.CULTURE]: {
    lapinkansafi: '#F4E9F2',
    kalevafi: '#FAF3ED',
  },
};

const genreThemesAccentColorByChannel = {
  [THEMES.SPORTS]: {
    lapinkansafi: null,
    kalevafi: null,
  },
  [THEMES.CULTURE]: {
    lapinkansafi: '#FF009D',
    kalevafi: null,
  },
};

const genreThemesAccentColorDarkContentByChannel = {
  [THEMES.SPORTS]: {
    lapinkansafi: '#76BD22',
    kalevafi: '#009AC1',
  },
  [THEMES.CULTURE]: {
    lapinkansafi: '#FF009D',
    kalevafi: null,
  },
};

const genreThemesDarkCtaHoverColorByChannel = {
  [THEMES.SPORTS]: {
    lapinkansafi: '#6BAC1F',
    kalevafi: '#0085A7',
  },
  [THEMES.CULTURE]: {
    lapinkansafi: '#ED0092',
    kalevafi: '#F18229',
  },
};

const GENRE_THEME_DARK_BG_COLOR = '#2d2d2d';

export const isDarkContent = () => !!document.querySelector('body.videosite, body.gallery, .article__body.-rich');

export const getGenreThemeBgColor = (theme, channelSlug) =>
  isDarkContent() ? GENRE_THEME_DARK_BG_COLOR : genreThemesBgColorByChannel[theme][channelSlug];

export const getGenreThemeAccentColor = (theme, channelSlug) =>
  isDarkContent()
    ? genreThemesAccentColorDarkContentByChannel[theme][channelSlug]
    : genreThemesAccentColorByChannel[theme][channelSlug];

export const getGenreThemeCtaHoverColor = (theme, channelSlug) =>
  isDarkContent() ? genreThemesDarkCtaHoverColorByChannel[theme][channelSlug] : null;

export const isRegionalIssue = (channelSlug) => channelSlug === 'lapinkansafi' || channelSlug === 'kalevafi';

export const isGenreTheme = (theme, channelSlug) =>
  [THEMES.CULTURE, THEMES.SPORTS].includes(theme) && isRegionalIssue(channelSlug);

export const isPrideTheme = (theme) => theme === THEMES.PRIDE;

const appendChannelSlugToUrl = (url, channelSlug) => {
  const dotIndex = url.lastIndexOf('.');
  return `${url.slice(0, dotIndex)}--${channelSlug}${url.slice(dotIndex)}`;
};

export const addBackgroundImageToElem = (element, url) => {
  element.style.backgroundImage = `url("${url}")`;
};

export const removeBackgroundImageFromElem = (element) => {
  element.style.backgroundImage = 'none';
};

export const appendChannelSlugToImage = (img, attribute, channelSlug) => {
  const url = img.getAttribute(attribute);
  const updatedUrl = appendChannelSlugToUrl(url, channelSlug);
  img.setAttribute(attribute, updatedUrl);
};

export const addImgSrc = (img) => {
  const { src } = img.dataset;
  img.setAttribute('src', src);
};

// Recursive function to set the snow frame height once the image has been painted to the screen
// Load event completes before the image is painted to the screen
export const setSnowFrameHeight = (headerImg, container) => {
  if (!headerImg || !container) return;

  requestAnimationFrame(() => {
    if (headerImg.offsetHeight === 0) {
      setSnowFrameHeight(headerImg, container); // Request a new animation frame if the image height is still 0
    }
    container.style.setProperty('--snow-frame-height', `${headerImg.offsetHeight}px`);
  });
};

export const customPohjoisenPolutStyles = (theme) => {
  if (![THEMES.SPRING, THEMES.AUTUMN, THEMES.WINTER, THEMES.SPRING, THEMES.NORMAL, THEMES.DARK].includes(theme)) return;

  document.querySelector('.paywall__main-title').classList.add('paywall__main-title--pohjoisenpolutfi');
};

const THEME_IMG_CONFIG = {
  HEADER_IMG: {
    header: 'png',
  },
  HEADER_AND_FOOTER_IMG: {
    header: 'png',
    footer: 'png',
  },
  HEADER_ANIMATION: {
    header: 'gif',
  },
  HEADER_AND_FOOTER_ANIMATION: {
    header: 'gif',
    footer: 'gif',
  },
};

const THEME_TO_THEME_IMG_CONFIG = {
  [THEMES.KALEVA]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.KOILLISSANOMAT]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.LAPINKANSA]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.RAAHENSEUTU]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.SIIKAJOKILAAKSO]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.RANTALAKEUS]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.IIJOKISEUTU]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.PYHAJOKISEUTU]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.SPORTS]: THEME_IMG_CONFIG.HEADER_AND_FOOTER_IMG,
  [THEMES.CULTURE]: THEME_IMG_CONFIG.HEADER_AND_FOOTER_IMG,
  [THEMES.SPRING]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.SUMMER]: THEME_IMG_CONFIG.HEADER_ANIMATION,
  [THEMES.AUTUMN]: THEME_IMG_CONFIG.HEADER_ANIMATION,
  [THEMES.WINTER]: THEME_IMG_CONFIG.HEADER_ANIMATION,
  [THEMES.SINGLES_DAY]: THEME_IMG_CONFIG.HEADER_AND_FOOTER_IMG,
  [THEMES.BLACKFRIDAY]: THEME_IMG_CONFIG.HEADER_AND_FOOTER_IMG,
  [THEMES.PRIDE]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.LIVE]: {},
  [THEMES.CHRISTMAS]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.CULTURE]: THEME_IMG_CONFIG.HEADER_AND_FOOTER_IMG,
  [THEMES.EASTER]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.FATHERS_DAY]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.INDEPENDENCE_DAY]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.MAY_DAY]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.MIDSUMMER]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.MOTHERS_DAY]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.NEW_YEAR]: THEME_IMG_CONFIG.HEADER_IMG,
  [THEMES.VALENTINES_DAY]: THEME_IMG_CONFIG.HEADER_AND_FOOTER_IMG,
  [THEMES.GOOD_NEWS]: THEME_IMG_CONFIG.HEADER_IMG,
};

export const getThemeImgConfig = (theme) => THEME_TO_THEME_IMG_CONFIG[theme];

const liveThemeAnimationColorsByChannel = {
  kalevafi: {
    'color-1': '#502400',
    'color-2': '#FF8C30',
    'color-3': '#C56920',
    'color-4': '#8A4710',
  },
  lapinkansafi: {
    'color-1': '#0B3F60',
    'color-2': '#0075BF',
    'color-3': '#04639F',
    'color-4': '#075180',
  },
  koillissanomatfi: {
    'color-1': '#192E00',
    'color-2': '#76BD22',
    'color-3': '#578D17',
    'color-4': '#385E0B',
  },
  raahenseutufi: {
    'color-1': '#192E00',
    'color-2': '#76BD22',
    'color-3': '#578D17',
    'color-4': '#385E0B',
  },
  iijokiseutufi: {
    'color-1': '#0B3F60',
    'color-2': '#0073BB',
    'color-3': '#04629D',
    'color-4': '#07507E',
  },
  siikajokilaaksofi: {
    'color-1': '#0B3F60',
    'color-2': '#0073BB',
    'color-3': '#04629D',
    'color-4': '#07507E',
  },
  pyhajokiseutufi: {
    'color-1': '#00485A',
    'color-2': '#009AC1',
    'color-3': '#007593',
    'color-4': '#005F76',
  },
  rantalakeusfi: {
    'color-1': '#3A0900',
    'color-2': '#DE3414',
    'color-3': '#A7260D',
    'color-4': '#711707',
  },
};

export const setLIveAnimationColors = (channelSlug) => {
  const liveThemeAnimationColors = liveThemeAnimationColorsByChannel[channelSlug];
  if (!liveThemeAnimationColors) return;

  Object.keys(liveThemeAnimationColors).forEach((color) => {
    document.documentElement.style.setProperty(`--animation-${color}`, liveThemeAnimationColors[color]);
  });
};
