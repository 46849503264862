/* eslint-disable no-use-before-define */
const initModal = (modalContentClass) => {
  const content = document.querySelector(`.${modalContentClass}`);
  if (!content) return null;

  const modalInnerHTML = content.outerHTML.replace('epaper-wall--hidden', '');
  const modal = document.createElement('div');
  modal.classList.add('modal');
  modal.innerHTML = modalInnerHTML;
  content.parentNode.replaceChild(modal, content);

  const focusableElements = [...modal.querySelectorAll('.epaper__cta-link, .modal-close-btn, a.epaper__login-link')];
  const firstFocusableElement = focusableElements[0];
  const lastFocusableElement = focusableElements[focusableElements.length - 1];

  const focusTrap = (e) => {
    if (e.key === 'Tab') {
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else if (document.activeElement === lastFocusableElement) {
        e.preventDefault();
        firstFocusableElement.focus();
      }
    }
  };

  const openModal = (thumbnailSrc) => {
    modal.querySelector('.epaper__thumbnail').src = `${thumbnailSrc}?mode=stretch&height=440&format=jpeg`;
    modal.classList.add('modal--open');
    document.addEventListener('keydown', keyHandler);
    modal.querySelector('.epaper__cta-link').focus();
  };

  const closeModal = () => {
    modal.classList.remove('modal--open');
    modal.querySelector('.epaper__thumbnail').src = '';
    document.removeEventListener('keydown', keyHandler);
  };

  const keyHandler = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }

    focusTrap(e);
  };

  const clickHandler = (e) => {
    if (e.target === modal) {
      closeModal();
    }
  };

  modal.querySelector('.modal-close-btn').addEventListener('click', closeModal);
  document.addEventListener('click', clickHandler);

  return {
    openModal,
    closeModal,
  };
};

export default initModal;
