/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import {
  THEMES,
  debounce,
  isGenreTheme,
  isPrideTheme,
  isRegionalIssue,
  appendChannelSlugToImage,
  getGenreThemeBgColor,
  addImgSrc,
  addBackgroundImageToElem,
  removeBackgroundImageFromElem,
  shouldSwapImageOnresize,
  setSnowFrameHeight,
  customPohjoisenPolutStyles,
  isDarkContent,
  setLIveAnimationColors,
  getGenreThemeAccentColor,
  getGenreThemeCtaHoverColor,
} from './utils/themeUtils';

const applyTheme = () => {
  try {
    const PAYWALL_THEME_BREAKPOINT = 600;
    const container = document.querySelector('.paywall-css-container');
    const { theme } = container.dataset;
    const channelSlug = window.digitalData.channel.slug;
    const headerImg = document.querySelector('.paywall__header-img');
    const footerImg = document.querySelector('.paywall__footer-img');
    const displayedImages = [headerImg, footerImg].filter((img) => img);

    const swapImageOnResize = (img) => {
      let updatedUrl = img.getAttribute('src');

      if (container.offsetWidth < PAYWALL_THEME_BREAKPOINT) {
        updatedUrl = updatedUrl.replace('-desktop', '-mobile');
      } else {
        updatedUrl = updatedUrl.replace('-mobile', '-desktop');
      }
      img.setAttribute('src', updatedUrl);
    };

    const adjustTitleFont = () => {
      const mainTitle = container.querySelector('.paywall__main-title');
      if (container.offsetWidth < PAYWALL_THEME_BREAKPOINT) {
        mainTitle.classList.remove('paywall__main-title--desktop');
      } else {
        mainTitle.classList.add('paywall__main-title--desktop');
      }
    };

    // Set channel specific pride theme image for Kaleva/Lapinkansa
    const swapPrideImage = () => {
      if (!isRegionalIssue(channelSlug)) return;
      appendChannelSlugToImage(headerImg, 'src', channelSlug);
    };

    // Set channel specific image for Kaleva/Lapinkansa for Kulttuuri and Urheilu themes
    const setGenreThemeImage = () => {
      [headerImg, footerImg].forEach((img) => {
        if (isDarkContent()) {
          img.dataset.src = img.dataset.src.replace(/((culture-|sports-)\w*-)/g, '$1dark-');
        }
        appendChannelSlugToImage(img, 'data-src', channelSlug);
      });
    };

    const setGenreThemeContentBox = () => {
      const box = container.querySelector('.paywall__genre-theme-box');
      box.textContent = channelSlug === 'lapinkansafi' ? 'Lapin kansa digi' : 'Kaleva digi';
    };

    // Toggle genre theme layout (for Kaleva/Lapinkansa Kulttuuri and Urheilu themes)
    const toggleGenreThemeLayout = () => {
      const backgroundImageUrlDesktop = headerImg.dataset.src.replace('-mobile', '-desktop');
      const contentContainer = container.querySelector('.paywall__content');
      container.style.setProperty('--zephr-channel-theme-genre-bg-color', getGenreThemeBgColor(theme, channelSlug));
      const accentColor = getGenreThemeAccentColor(theme, channelSlug);
      const ctaHoverColor = getGenreThemeCtaHoverColor(theme, channelSlug);

      if (accentColor) {
        container.style.setProperty('--zephr-checkmark-color', accentColor);
      }

      if (ctaHoverColor) {
        container.style.setProperty('--zephr-cta-hover-color', ctaHoverColor);
      }

      if (container.offsetWidth < PAYWALL_THEME_BREAKPOINT) {
        [headerImg, footerImg].forEach(addImgSrc);

        container.offsetWidth < 520
          ? contentContainer.classList.remove('paywall__content--bottom-padding')
          : contentContainer.classList.add('paywall__content--bottom-padding');

        removeBackgroundImageFromElem(container);
        contentContainer.classList.remove('paywall__content--desktop');
        headerImg.classList.add('paywall__header-img--show');
        footerImg.classList.add('paywall__footer-img--show');
      } else {
        container.offsetWidth < 920
          ? contentContainer.classList.remove('paywall__content--horizontal-padding-desktop-xl')
          : contentContainer.classList.add('paywall__content--horizontal-padding-desktop-xl');

        contentContainer.classList.add('paywall__content--desktop');
        headerImg.classList.remove('paywall__header-img--show');
        footerImg.classList.remove('paywall__footer-img--show');
        addBackgroundImageToElem(container, backgroundImageUrlDesktop);
      }
    };

    // Adjust "sinkku" countdown element styles based on parent container.
    // Consider using CSS container queries once Kaleva requirements for supported browsers allow it.
    const adjustCounterStyles = () => {
      if (theme !== 'sinkku') return;

      const counter = container.querySelector('.paywall__counter--sinkku');

      if (container.offsetWidth < PAYWALL_THEME_BREAKPOINT) {
        counter.classList.remove('--position-absolute', '--position-absolute-desktop');
      } else if (container.offsetWidth < 1024) {
        counter.classList.remove('--position-absolute-desktop');
        counter.classList.add('--position-absolute');
      } else {
        counter.classList.remove('--position-absolute');
        counter.classList.add('--position-absolute-desktop');
      }
    };

    const applyLiveTheme = () => {
      setLIveAnimationColors(channelSlug);
      document.querySelector('.paywall__live-theme-box').style = 'display: flex;';

      const channelIcon = container.querySelector('.live-theme-box__channel-icon');
      appendChannelSlugToImage(channelIcon, 'data-src', channelSlug);
      addImgSrc(channelIcon);
    };

    const applyValentinesDayTheme = () => {
      if (!isDarkContent()) return;

      displayedImages.forEach(
        // eslint-disable-next-line no-return-assign
        (img) => (img.dataset.src = img.dataset.src.replace(/(valentines-day-\w*-)/g, '$1dark-')),
      );

      const DARK_CONTENT_HEADER_OVERFLOW_IMG_HEIGHT = 250;
      const DARK_CONTENT_HEADER_OVERFLOW_IMG_OFFSET_Y = 40;
      const contentContainer = container.querySelector('.paywall__content');

      const setPaywallContentTopPadding = (headerImgHeight) => {
        const requiredPadding = container.classList.contains('paywall-css-container--desktop')
          ? DARK_CONTENT_HEADER_OVERFLOW_IMG_HEIGHT - headerImgHeight - DARK_CONTENT_HEADER_OVERFLOW_IMG_OFFSET_Y
          : 0;
        contentContainer.style.paddingTop = `${requiredPadding}px`;
      };

      const headerImgResizeObserver = new ResizeObserver((entries) => {
        entries.forEach(debounce((entry) => setPaywallContentTopPadding(entry.target.offsetHeight), 150));
      });

      headerImg.addEventListener('load', () => setPaywallContentTopPadding(headerImg.offsetHeight));
      headerImgResizeObserver.observe(headerImg);
    };

    const applyGoodNewsTheme = () => {
      if (!isDarkContent()) return;

      const setImgHeaderHeightCSSVariable = (height) => {
        document
          .querySelector('.paywall-css-container.-type-pay.-theme-good-news')
          ?.style.setProperty('--header-img-height', `${height}px`);
      };

      const headerImgResizeObserver = new ResizeObserver((entries) => {
        entries.forEach(debounce((entry) => setImgHeaderHeightCSSVariable(entry.target.offsetHeight), 150));
      });

      headerImg.addEventListener('load', () => setImgHeaderHeightCSSVariable(headerImg.offsetHeight));
      headerImgResizeObserver.observe(headerImg);
    };

    // APPLY THEME
    if (theme === THEMES.GOOD_NEWS) {
      applyGoodNewsTheme();
    }

    if (theme === THEMES.SPRING) {
      setSnowFrameHeight(headerImg, container);

      window.addEventListener(
        'resize',
        debounce(() => {
          container.style.setProperty('--snow-frame-height', `${headerImg.offsetHeight}px`);
        }),
        150,
      );
    }

    if (channelSlug === 'pohjoisenpolutfi') {
      customPohjoisenPolutStyles(theme);
    }
    const resizeListener = () => {
      if (container.offsetWidth < PAYWALL_THEME_BREAKPOINT) {
        container.classList.remove('paywall-css-container--desktop');
      } else {
        container.classList.add('paywall-css-container--desktop');
      }
    };

    resizeListener();
    window.addEventListener('resize', debounce(resizeListener, 150));

    if (isGenreTheme(theme, channelSlug)) {
      setGenreThemeContentBox();
      setGenreThemeImage();
      toggleGenreThemeLayout();
      window.addEventListener('resize', debounce(toggleGenreThemeLayout, 150));
    }

    if (theme === THEMES.LIVE) {
      applyLiveTheme();
    }

    if (theme === THEMES.VALENTINES_DAY) {
      applyValentinesDayTheme();
    }

    displayedImages.forEach(addImgSrc);

    if (isPrideTheme(theme)) {
      swapPrideImage();
    }

    if (shouldSwapImageOnresize(theme)) {
      displayedImages.forEach(swapImageOnResize);

      window.addEventListener(
        'resize',
        debounce(() => displayedImages.forEach(swapImageOnResize), 150),
      );
    }

    adjustCounterStyles();
    adjustTitleFont();

    window.addEventListener('resize', debounce(adjustCounterStyles, 150));
    window.addEventListener('resize', debounce(adjustTitleFont, 150));
  } catch (error) {
    if (window.digitalData.page.attributes.environment === 'prod') return;
    // eslint-disable-next-line no-console
    console.log('applyTheme(): ', error);
  }
};

export default applyTheme;
