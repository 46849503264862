/* eslint-disable no-param-reassign */
import { THEMES } from './utils/themeUtils';

const { BLACKFRIDAY, SINGLES_DAY, VALENTINES_DAY } = THEMES;

const UNIT_LABELS_FI = {
  hours: {
    one: 'tunti',
    other: 'tuntia',
  },
  minutes: {
    one: 'minuutti',
    other: 'minuuttia',
  },
  seconds: {
    one: 'sekunti',
    other: 'sekuntia',
  },
};

const getLabel = (unit, value) => UNIT_LABELS_FI[unit][value === 1 ? 'one' : 'other'];

const formatTime = (value) => {
  const time = value.toString();
  return time.length === 1 ? time.padStart(2, 0) : time;
};

const isValidTimestamp = (timestampSeconds) => {
  if (!timestampSeconds) return false;
  const timestampInMS = timestampSeconds * 1000;
  const currentTimestampInMS = Math.floor(Date.now());
  const futureTimestampInMS = currentTimestampInMS + 4 * 24 * 60 * 60 * 1000;
  // not in the past && not more than 4 days in the future
  return timestampInMS > currentTimestampInMS && timestampInMS < futureTimestampInMS;
};

const initCounter = () => {
  const paywallElement = document.querySelector('.paywall-css-container');
  const timestamp = paywallElement?.dataset?.timestamp;
  const { theme } = paywallElement.dataset;
  const isValidTheme = [BLACKFRIDAY, SINGLES_DAY, VALENTINES_DAY].includes(theme);

  if (!isValidTimestamp(timestamp) || !isValidTheme) return;

  paywallElement.classList.add('-with-counter');
  const counterRoot = paywallElement.querySelector('.paywall__counter');
  counterRoot.style.display = 'block';

  const latestValues = {
    hours: {
      value: null,
      label: null,
    },
    minutes: {
      value: null,
      label: null,
    },
    seconds: {
      value: null,
      label: null,
    },
  };

  const updateDisplay = (node, unit, newValue) => {
    if (latestValues[unit].value === newValue) return;
    latestValues[unit].value = newValue;
    node.innerText = formatTime(newValue);
  };

  const updateLabel = (node, unit, newValue) => {
    const newLabel = getLabel(unit, newValue);
    if (latestValues[unit].label === newLabel) return;
    latestValues[unit].label = newLabel;
    node.innerText = newLabel;
  };

  const intervalId = setInterval(() => {
    const timeLeft = timestamp - Math.floor(Date.now() / 1000);
    const hours = Math.floor(timeLeft / (60 * 60));
    const minutes = Math.floor((timeLeft / 60) % 60);
    const seconds = Math.floor(timeLeft % 60);

    if (hours + minutes + seconds <= 0) {
      clearInterval(intervalId);
    }

    [
      ['hours', hours],
      ['minutes', minutes],
      ['seconds', seconds],
    ].forEach(([unit, value]) => {
      updateDisplay(counterRoot.querySelector(`.counter__unit-display.${unit}`), unit, value);
      updateLabel(counterRoot.querySelector(`.counter__unit-label.${unit}`), unit, value);
    });
  }, 1000);
};

export default initCounter;
